<template lang="pug">
	div.card-box
		.card.questions-card.bant(v-bind:class="{disable: !cardStatus}")
			CardHeader(:status="cardStatus", title="BANT Questions", slug="BANT", v-model="cardStatus", file="https://demand-hourglass.infuse.com/staticfiles/BANT_Leads_Guide.pdf")
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.bants.length)}")
				p Pursue the most qualified prospects based on their budget, authority, needs, and timeline.

			//- .questions-list
			//- 	.single(v-for="(item, index) in questions")
			//- 		h4 {{ item.title }}
			//- 		.question-settings(v-on:click="editQuestion(index)")
			//- 			p {{ item.question }}
			//- 			.settings
			//- 				svg(width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg")
			//- 					path(d="M9.96641 5.56628C9.98696 5.3804 10 5.19191 10 5.00016C10 4.80841 9.98696 4.61993 9.96609 4.43372L8.53845 3.92597C8.46736 3.69248 8.37409 3.46943 8.26127 3.25746L8.91085 1.89043C8.67444 1.59395 8.40558 1.32487 8.10931 1.08821L6.74232 1.73912C6.52925 1.62474 6.30531 1.53189 6.07383 1.46193L5.56643 0.034241C5.38023 0.0133703 5.19174 0 5 0C4.80826 0 4.61977 0.0130442 4.43357 0.034241L3.92617 1.46193C3.69269 1.53269 3.46964 1.62563 3.25768 1.73912L1.89037 1.08821C1.59362 1.32464 1.32492 1.59367 1.08818 1.89043L1.73906 3.25681C1.62558 3.46878 1.53264 3.69216 1.46188 3.92532L0.0342399 4.43405C0.0133699 4.61993 0 4.80907 0 5.00049C0 5.19191 0.0130438 5.3804 0.0342399 5.56661L1.46188 6.07468C1.53264 6.30784 1.62558 6.53122 1.73906 6.74319L1.0885 8.11022C1.32492 8.40698 1.59395 8.67536 1.89069 8.91179L3.2567 8.26121C3.46866 8.37469 3.69204 8.46796 3.92519 8.53905L4.43325 9.96641C4.61912 9.98696 4.8076 10 4.99967 10C5.19142 10 5.37957 9.98696 5.56577 9.96609L6.07383 8.5384C6.30698 8.46731 6.53036 8.37404 6.74232 8.26121L8.10931 8.91081C8.40605 8.67439 8.67443 8.40535 8.91085 8.10925L8.26094 6.74254C8.37442 6.53155 8.46768 6.30752 8.53877 6.07403L9.96641 5.56628ZM5 6.58829C4.79141 6.58834 4.58485 6.54729 4.39212 6.4675C4.19939 6.38772 4.02426 6.27075 3.87673 6.12328C3.57879 5.82544 3.41136 5.42145 3.41127 5.00016C3.41118 4.57888 3.57845 4.17481 3.87627 3.87686C4.17409 3.57891 4.57808 3.41147 4.99935 3.41138C5.42062 3.41129 5.82467 3.57857 6.12261 3.8764C6.42056 4.17423 6.58799 4.57823 6.58808 4.99951C6.58816 5.4208 6.4209 5.82486 6.12308 6.12281C5.82525 6.42077 5.42127 6.58821 5 6.58829Z" fill="#4F4F4F")


			//- 		DropBox(
			//- 			:list="item.answers",
			//- 			:default="item.selected",
			//- 			v-model="item.selected"
			//- 		)

			.questions-list(v-if="GetSettings.bants.length")
				.single(v-for="(item, index) in GetSettings.bants")
					h4 {{ item.question.kind }}
					.question-settings
						p {{ item.question_txt != null ? item.question_txt : item.question.question }}
						.settings(v-on:click="editQuestion(index)")
							svg(width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg")
								path(d="M9.96641 5.56628C9.98696 5.3804 10 5.19191 10 5.00016C10 4.80841 9.98696 4.61993 9.96609 4.43372L8.53845 3.92597C8.46736 3.69248 8.37409 3.46943 8.26127 3.25746L8.91085 1.89043C8.67444 1.59395 8.40558 1.32487 8.10931 1.08821L6.74232 1.73912C6.52925 1.62474 6.30531 1.53189 6.07383 1.46193L5.56643 0.034241C5.38023 0.0133703 5.19174 0 5 0C4.80826 0 4.61977 0.0130442 4.43357 0.034241L3.92617 1.46193C3.69269 1.53269 3.46964 1.62563 3.25768 1.73912L1.89037 1.08821C1.59362 1.32464 1.32492 1.59367 1.08818 1.89043L1.73906 3.25681C1.62558 3.46878 1.53264 3.69216 1.46188 3.92532L0.0342399 4.43405C0.0133699 4.61993 0 4.80907 0 5.00049C0 5.19191 0.0130438 5.3804 0.0342399 5.56661L1.46188 6.07468C1.53264 6.30784 1.62558 6.53122 1.73906 6.74319L1.0885 8.11022C1.32492 8.40698 1.59395 8.67536 1.89069 8.91179L3.2567 8.26121C3.46866 8.37469 3.69204 8.46796 3.92519 8.53905L4.43325 9.96641C4.61912 9.98696 4.8076 10 4.99967 10C5.19142 10 5.37957 9.98696 5.56577 9.96609L6.07383 8.5384C6.30698 8.46731 6.53036 8.37404 6.74232 8.26121L8.10931 8.91081C8.40605 8.67439 8.67443 8.40535 8.91085 8.10925L8.26094 6.74254C8.37442 6.53155 8.46768 6.30752 8.53877 6.07403L9.96641 5.56628ZM5 6.58829C4.79141 6.58834 4.58485 6.54729 4.39212 6.4675C4.19939 6.38772 4.02426 6.27075 3.87673 6.12328C3.57879 5.82544 3.41136 5.42145 3.41127 5.00016C3.41118 4.57888 3.57845 4.17481 3.87627 3.87686C4.17409 3.57891 4.57808 3.41147 4.99935 3.41138C5.42062 3.41129 5.82467 3.57857 6.12261 3.8764C6.42056 4.17423 6.58799 4.57823 6.58808 4.99951C6.58816 5.4208 6.4209 5.82486 6.12308 6.12281C5.82525 6.42077 5.42127 6.58821 5 6.58829Z" fill="#4F4F4F")

					DropBoxANS(
						:list="item.question.answers",
						:default="item.answer_txt != null ? item.answer_txt : item.answer.answer",
						v-model="item.answer",
						v-on:input="updateAnswer(index)"
					)

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Pursue the most qualified prospects based on their budget, authority, needs, and timeline.

			//- button.btn.add-new-btn(type="button", v-on:click="saveToBD()") Test it

		.popup-cn(v-if="popupQuestion")
			.overclick(v-on:click="showHidePopups()")
			.popup.change-question(v-if="popupQuestion")
				.close(v-on:click="showHidePopups()")
				.inside-form
					label Question
					input(type="text", placeholder="Text here...", v-model="customQuestion", v-bind:class="{error: questionError}", autocomplete="xz").inp

					div(v-for="(item, index) in customAnswer")
						label Answer 
							span(v-if="customAnswer.length > 1") {{index + 1}}
							span(v-on:click="removeAnswer(index)", v-if="index != 0").remove-ans Remove
						input(
							type="text",
							placeholder="Text here...",
							v-model="customAnswer[index].answer",
							v-bind:class="{error: answerErrorIndex[index]}"
							autocomplete="xz"
						).inp
						//- input(
						//- 	type="text",
						//- 	placeholder="Text here...",
						//- 	v-model="customAnswer[index]",
						//- 	v-bind:class="{error: answerErrorIndex[index]}"
						//- ).inp

					div(v-if="editIndex")
						p.add-answer(
							v-on:click="addMoreAnswers()",
							v-if="(customAnswer.length - 1 + GetSettings.bants[editIndex].question.answers.length) < 5"
						) Add Answer

					div(v-else)
						p.add-answer(
							v-on:click="addMoreAnswers()",
							v-if="(customAnswer.length - 1) < 5"
						) Add Answer

					button.btn.simple(type="button", v-on:click="saveQuestion()") SAVE CHANGES

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropBoxANS from "../components/drop-box-ans.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'BantQustions',
		components:{
			CardHeader,
			DropBoxANS
		},
		data() {
			return {
				cardStatus: true,
				popupQuestion: false,
				customQuestion: '',
				customAnswer: [],
				answerErrorIndex: [],
				editIndex: null,
				originalAnswer: null,
				check: '',
				kind: '',
				pos: '',
				questionError: false
			}
		},
		created(){
			this.sortFunc(this.GetSettings.bants);
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "BANT") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			sortFunc: function (obj){
				return obj.sort((a, b) => a.question.pos - b.question.pos );
			},
			editQuestion(index){
				if (this.cardStatus) {
					this.editIndex = index;
					this.kind = this.GetSettings.bants[this.editIndex].question.kind;
					this.pos = this.GetSettings.bants[this.editIndex].question.pos;
					this.customQuestion = this.GetSettings.bants[this.editIndex].question.question;
					this.customAnswer = this.GetSettings.bants[this.editIndex].question.answers;
					this.originalAnswer = this.GetSettings.bants[this.editIndex].answer.id;
					this.answerErrorIndex = [];

					this.popupQuestion = !this.popupQuestion;
					this.$emit('input', this.popupQuestion);
				}
			},
			updateAnswer(index){
				this.GetSettings.bants[index].answer_txt = null;
				this.updateSettings(['bants', this.GetSettings.bants]);
				this.showHidePopups();
			},
			showHidePopups(){
				this.customQuestion = '';
				this.customAnswer = [];
				this.answerErrorIndex = [];
				this.editIndex = null;
				this.kind = '';
				this.pos = '';
				this.originalAnswer = null;
				this.popupQuestion = false;
				this.$emit('input', false);
			},
			saveQuestion(){
				var errors = false;
				for (var i = 0; i <= this.customAnswer.length - 1; i++) {
					if (this.customAnswer[i].answer.length < 1) {
						this.$set(this.answerErrorIndex, i, true)
						errors = true;
					}else{
						this.$set(this.answerErrorIndex, i, false)
					}
				}
				if (this.customQuestion.length < 1) {
					this.questionError = true;
					errors = true;
				}
				setTimeout(() => {
					this.answerErrorIndex = [];
					this.questionError = false;
					errors = false;
				}, 3000)
				if (!errors) {
					//
					var newBant = {
						question: {
							answers: [],
							kind: this.kind,
							pos: this.pos,
							question: this.customQuestion.trim()
						},
						answer: {
							answer: this.customAnswer[0].answer.trim()
						}
					}

					for(var answer in this.customAnswer){
						newBant.question.answers.push({answer: this.customAnswer[answer].answer.trim()});
					}

					this.$set(this.GetSettings.bants, this.editIndex, newBant);
					this.updateSettings(['bants', this.GetSettings.bants]);
					this.editIndex = null;
					this.originalAnswer = null;
					this.customAnswer = [];
					this.answerErrorIndex = [];
					this.kind = '';
					this.pos = '';
					this.showHidePopups();
				}
			},
			addMoreAnswers(){
				this.customAnswer.push({'answer': ''});
				this.answerErrorIndex.push(false)
			},
			removeAnswer(index){
				this.customAnswer.splice(index, 1);
				this.answerErrorIndex.splice(index, 1);
			},
			saveToBD(){
				var usersQuestion = [];

				for (var q = 0; q <= this.GetSettings.bants.length - 1; q++) {
					if (!this.GetSettings.bants[q].question.id) {
						usersQuestion.push(this.GetSettings.bants[q])
					}
				}

				for (var i = 0; i <= usersQuestion.length - 1; i++) {
					var form = usersQuestion[i]
					window.api.post('campaign_settings/bant_questions/', form.question)
						.then(bantQuestion => {
							var index = this.GetSettings.bants.map(e => e.question.question).indexOf(bantQuestion.data.question);
							var ansIndex = bantQuestion.data.answers.map(e => e.answer).indexOf(form.answer.answer);
							var obj = {
								answer: bantQuestion.data.answers[ansIndex],
								question: bantQuestion.data
							}
							this.$set(this.GetSettings.bants, index, obj);
							this.updateSettings(['bants', this.GetSettings.bants]);
						})
						.catch(bantError => {
							console.log(bantError.response);
						})
				}

				// window.api.post('campaign/'+352+'/create_bant/', newBant)
				// 	.then(bantQuestion => {
				// 		console.log(bantQuestion.data)
				// 	})
				// 	.catch(bantError => {
				// 		console.log(bantError.response.data);
				// 	})
			}
		}
	}
</script>