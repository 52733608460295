<template lang="pug">
	div.card-box
		.card.nurturing(v-bind:class="{disable: !cardStatus}")
			CardHeader(
				:status="cardStatus",
				title="Nurturing",
				v-model="cardStatus"
				type="tooltip",
				slug="Nurturing",
				:tooltipText="tooltipText",
				file="https://demand-hourglass.infuse.com/staticfiles/Lead_Nurturing.pdf"
			)
			
			.card-off(v-bind:class="{show: (!cardStatus && GetSettings.nurturings.length)}")
				p Transform top-of-funnel leads into high-quality opportunities.

			div(v-if="GetSettings.nurturings.length")
				.nurturing-table
					.t-head
						.cl
							p Stages

						.cl
							p Assets Used

						.cl
							p Creatives

						//- .cl
						//- 	p Lead Goal
							
						//- .cl
						//- 	p Leads

					.t-row(v-for="(item, index) in GetSettings.nurturings", v-bind:class="{'active': item.state == 'running'}")
						.cl
							.action-play-pause(
								v-if="item.state",
								v-bind:class="{played: item.state == 'running'}"
								v-on:click="changeAssetStatus(index)"
							)
								.play
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(x="0.5" y="0.5" width="19" height="19" rx="2.5").stroke
										path(d="M7.23822 5.057C7.30311 5.019 7.37378 5 7.44444 5C7.53333 5 7.62133 5.03 7.69733 5.089L13.4751 9.5891C13.5951 9.6826 13.6667 9.83611 13.6667 10.0001C13.6667 10.1641 13.5951 10.3176 13.4751 10.4111L7.69733 14.9112C7.56178 15.0167 7.38444 15.0292 7.23822 14.9432C7.09156 14.8567 7 14.6862 7 14.5002V5.50001C7 5.31401 7.09156 5.1435 7.23822 5.057Z").fill
								.pause
									svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
										rect(width="20" height="20" rx="3").f-f
										rect(x="7" y="5" width="2" height="10" rx="1").f-t
										rect(x="11" y="5" width="2" height="10" rx="1").f-t

							p {{ item.nurturing_stages.name }}

						.cl
							p(v-if="item.assets") {{ item.assets.name }}
							p(v-else) -

						.cl
							a(:href="item.link", v-if="item.link", target="_blank").link Open
								.ico
									svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
										path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
										path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

							.link(v-else-if="item.link == false").not-active Open
								.ico
									svg(xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none")
										path(d="M7 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H13C13.5523 14 14 13.5523 14 13V8" stroke-linecap="round").stroke
										path(d="M14.5 1C14.5 0.723858 14.2761 0.5 14 0.5L9.5 0.500001C9.22386 0.5 9 0.723858 9 1C9 1.27614 9.22386 1.5 9.5 1.5L13.5 1.5L13.5 5.5C13.5 5.77614 13.7239 6 14 6C14.2761 6 14.5 5.77614 14.5 5.5L14.5 1ZM8.35355 7.35355L14.3536 1.35355L13.6464 0.646447L7.64645 6.64645L8.35355 7.35355Z").fill

							.add-t(v-else, v-on:click="addLP(index)", v-bind:class="{disable: item.requested}") Add
								.ico
									svg(width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg")
										circle(cx="7.5" cy="7.5" r="7" stroke="#15C179")
										line(x1="7.5" y1="4" x2="7.5" y2="11" stroke="#15C179")
										line(x1="4" y1="7.5" x2="11" y2="7.5" stroke="#15C179")

						.cl
							.remove-row(v-on:click="removeRow(index)")


						//- .cl
						//- 	p(v-if="item.assets.percent") {{ Number(GetSettings.total_main.leads_generated * (item.assets.percent / 100)).toFixed(0) }}
						//- 	p(v-else) -

						//- .cl
						//- 	p(v-if="item.genereted_leads") {{ item.genereted_leads }}
						//- 	p(v-else) -

				.params(v-if="GetSettings.nurturing_parameters")
					p Parameters:
						span {{ GetSettings.nurturing_parameters }}

					.settings(v-on:click="changeParams()")
						svg(width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg")
							path(d="M9.96641 5.56628C9.98696 5.3804 10 5.19191 10 5.00016C10 4.80841 9.98696 4.61993 9.96609 4.43372L8.53845 3.92597C8.46736 3.69248 8.37409 3.46943 8.26127 3.25746L8.91085 1.89043C8.67444 1.59395 8.40558 1.32487 8.10931 1.08821L6.74232 1.73912C6.52925 1.62474 6.30531 1.53189 6.07383 1.46193L5.56643 0.034241C5.38023 0.0133703 5.19174 0 5 0C4.80826 0 4.61977 0.0130442 4.43357 0.034241L3.92617 1.46193C3.69269 1.53269 3.46964 1.62563 3.25768 1.73912L1.89037 1.08821C1.59362 1.32464 1.32492 1.59367 1.08818 1.89043L1.73906 3.25681C1.62558 3.46878 1.53264 3.69216 1.46188 3.92532L0.0342399 4.43405C0.0133699 4.61993 0 4.80907 0 5.00049C0 5.19191 0.0130438 5.3804 0.0342399 5.56661L1.46188 6.07468C1.53264 6.30784 1.62558 6.53122 1.73906 6.74319L1.0885 8.11022C1.32492 8.40698 1.59395 8.67536 1.89069 8.91179L3.2567 8.26121C3.46866 8.37469 3.69204 8.46796 3.92519 8.53905L4.43325 9.96641C4.61912 9.98696 4.8076 10 4.99967 10C5.19142 10 5.37957 9.98696 5.56577 9.96609L6.07383 8.5384C6.30698 8.46731 6.53036 8.37404 6.74232 8.26121L8.10931 8.91081C8.40605 8.67439 8.67443 8.40535 8.91085 8.10925L8.26094 6.74254C8.37442 6.53155 8.46768 6.30752 8.53877 6.07403L9.96641 5.56628ZM5 6.58829C4.79141 6.58834 4.58485 6.54729 4.39212 6.4675C4.19939 6.38772 4.02426 6.27075 3.87673 6.12328C3.57879 5.82544 3.41136 5.42145 3.41127 5.00016C3.41118 4.57888 3.57845 4.17481 3.87627 3.87686C4.17409 3.57891 4.57808 3.41147 4.99935 3.41138C5.42062 3.41129 5.82467 3.57857 6.12261 3.8764C6.42056 4.17423 6.58799 4.57823 6.58808 4.99951C6.58816 5.4208 6.4209 5.82486 6.12308 6.12281C5.82525 6.42077 5.42127 6.58821 5 6.58829Z" fill="#4F4F4F")

			.empty-section(v-else)
				.ico
					img(src="@/assets/empty-section.svg", alt="")
				p Transform top-of-funnel leads into high-quality opportunities.

			button.btn.add-new-btn(type="button", v-on:click="addNurturing()") Additional Nurturing

		.popup.add-asset-lp(v-if="popupAddLP")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Creatives
				input(type="text", placeholder="Text here...", v-model="lpName").inp

				button.btn.simple(type="button", v-on:click="updatedLP()") SUBMIT

		.popup.change-param(v-if="popupChangeParams")
			.close(v-on:click="showHidePopups()")
			.inside-form
				label Parameters
				input(type="text", placeholder="Text here...", v-model="GetSettings.nurturing_parameters").inp

				button.btn.simple(type="button", v-on:click="updatedParams()") SAVE CHANGES

		.popup.new-nurturing(v-if="popupAddNurturing")
			.close(v-on:click="showHidePopups()")
			.inside-form
				.in-row
					.cl.full
						label Stage
						DropBoxInp(
							:list="GetSettings.nurturing_stages",
							:default="newNurturingForm.nurturing_stages.name",
							v-model="newNurturingForm.nurturing_stages",
							v-bind:class="{error: newNurturingeErrors.nurturing_stages}"
						)
					
				.in-row
					.cl.full
						label Asset
						DropBoxAssets(
							:list="GetSettings.assets",
							v-model="newNurturingForm.assets",
							default="",
							v-bind:class="{error: newNurturingeErrors.asset}"
						)

				button.btn.simple(type="button", v-on:click="addNewNurturing()") Apply

		.popup.asset-requested(v-if="popupAdd")
			.close(v-on:click="showHidePopups()")
			.inside-form
				p Your request is submitted. 
					br
					| Our Client Success Manager 
					br
					| will update the campaign shortly.
				button.btn.simple(type="button", v-on:click="showHidePopups()") OK

</template>

<script>
	import CardHeader from "../components/title-checkbox.vue";
	import DropList from "../components/drop-list.vue";
	import DropBoxAssets from "../components/drop-box-assets.vue";
	import DropBoxInp from "../components/drop-box-inp.vue";

	import { mapMutations, mapGetters } from 'vuex'

	export default {
		name: 'Nurturing',
		components:{
			CardHeader,
			DropList,
			DropBoxAssets,
			DropBoxInp
		},
		data() {
			return {
				generatedLeads: 719,
				cardStatus: true,
				tooltipText: '<p><b>Lead Nurturing at All Stages of the Funnel</b><p><p>The most popular add-on service to any demand generation program, our nurturing solution takes leads through a deliberate, well-thought-out outreach process.</p><p><b>The result?</b>—Nothing less than the highest return on your demand generation investment.</p><p><b>And the best part?</b>—You decide how far you want each lead to be nurtured. Each program is customized to your unique needs.</p>',
				popupAddLP: false,
				keyLP: '',
				lpName: '',
				parameters: 'Each touch will be done weekly',
				popupChangeParams: false,
				popupAddNurturing: false,
				newNurturingeErrors:{
					nurturing_stages: false,
					asset: false
				},
				fileDropHover: false,
				assetFile: [],
				newNurturingForm:{
					state: 'pause',
					nurturing_stages: '',
					genereted_leads: '',
					link: null,
					assets: ''
				},
				popupAdd: false
			}
		},
		created(){
			this.sortFunc(this.GetSettings.nurturings);
			this.updateSettings(['nurturings', this.GetSettings.nurturings]);
			for (var item in this.GetSettings.sections) {
				if (this.GetSettings.sections[item].slug == "Nurturing") {
					this.cardStatus = this.GetSettings.sections[item].enabled;
					return;
				}
			}
		},
		computed: { ...mapGetters(['GetSettings']) },
		methods: {
			...mapMutations(['updateSettings']),
			sortFunc: function (obj){
				return obj.sort((a, b) => a.id - b.id );
			},
			onlyNumber ($event) {
				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault();
				}
			},
			changeAssetStatus(index){
				if (this.cardStatus) {
					if(this.GetSettings.nurturings.[index].state == 'running'){
						this.GetSettings.nurturings.[index].state = 'pause'
					}else{
						this.GetSettings.nurturings.[index].state = 'running'
					}
				}
			},
			showHidePopups(){
				this.keyLP = '';
				this.lpName = '';
				this.popupAddLP = false;
				this.popupChangeParams = false;
				this.popupAddNurturing = false;
				this.newNurturingeErrors.nurturing_stages = false;
				this.newNurturingeErrors.asset = false;
				this.newNurturingForm = {
					state: 'pause',
					nurturing_stages: '',
					genereted_leads: '',
					link: null,
					assets: ''
				}
				this.popupAdd = false;
				this.$emit('input', false);
			},
			addLP(index){
				if (this.cardStatus) {
					this.GetSettings.nurturings[index].requested = true;
					this.updateSettings(['nurturings', this.GetSettings.nurturings]);
					this.popupAdd = true;
					this.$emit('input', this.popupAdd);
					// this.keyLP = index;
					// this.popupAddLP = !this.popupAddLP;
					// this.$emit('input', this.popupAddLP);
				}
			},
			updatedLP(){
				this.nurturingList[this.keyLP].landing_page = this.lpName;
				this.showHidePopups();
			},
			changeParams(){
				if (this.cardStatus) {
					this.popupChangeParams = !this.popupChangeParams;
					this.$emit('input', this.popupChangeParams);
				}
			},
			updatedParams(){
				this.updateSettings(['nurturing_parameters', this.GetSettings.nurturing_parameters]);
				this.showHidePopups();
			},
			addNurturing(){
				if (this.cardStatus) {
					this.popupAddNurturing = !this.popupAddNurturing;
					this.$emit('input', this.popupAddNurturing);
				}
			},
			addNewNurturing(){
				if (this.cardStatus) {
					if (!this.newNurturingForm.nurturing_stages.name) {
						this.newNurturingeErrors.nurturing_stages = true;
					}
					if(!this.newNurturingForm.assets.name){
						this.newNurturingeErrors.asset = true;
					}
					if(!this.newNurturingeErrors.nurturing_stages && !this.newNurturingeErrors.asset){
						var key = Object.keys(this.GetSettings.nurturings).length;
						this.$set(this.GetSettings.nurturings, key, this.newNurturingForm);
						this.updateSettings(['nurturings', this.GetSettings.nurturings]);
						this.showHidePopups();
						this.sortFunc(this.GetSettings.nurturings);
					}
					setTimeout(() => {
						this.newNurturingeErrors.nurturing_stages = false;
						this.newNurturingeErrors.asset = false;
					}, 2000)
				}
			},
			onChange() {
				this.assetFile = [...this.$refs.file.files];
			},
			drop(event) {
				event.preventDefault();
				this.$refs.file.files = event.dataTransfer.files;
				this.onChange();
			},
			dragover() {
				this.fileDropHover = true;
			},
			dragleave(){
				this.fileDropHover = false;
			},
			removeRow(index){
				this.GetSettings.nurturings.splice(index, 1);
				this.updateSettings(['nurturings', this.GetSettings.nurturings]);
			}
		}
	}
</script>