import axios from 'axios'
// import router from './router'
const apiClient = axios.create({
	// baseURL: 'http://44.193.230.107:8081/api/'
	// baseURL: 'http://178.208.94.201:8081/api/'
	//
	// baseURL: 'http://95.142.38.58:8081/api/'
	baseURL: 'https://demand-hourglass.infuse.com:8081/api/'
});


apiClient.interceptors.request.use(request => {
	const token = window.store.state.Token.access;
	if (token) {
		request.headers.Authorization = 'Bearer ' + token
	}
	return request
})

apiClient.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if (error.response.status) {
		switch (error.response.status) {
			case 401:{
				const refresh = window.store.state.Token.refresh;
				window.api.get("auth/token/refresh", {headers: {'Authorization': 'Refresh ' + refresh}}).then((response) => {
					if (response.status == 200) {
						window.Token.settoken(response.data);
						window.Token.init();
					}
				})
				break;
			}
			case 405:{
				window.Token.deletetoken();
				window.Auth.logout();
				document.location.href = "/login/";
				// router.push('login');
			}
		}
	}
    return Promise.reject(error)
});

export default apiClient